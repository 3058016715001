// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-graph-2-d-js": () => import("./../../../src/pages/graph2d.js" /* webpackChunkName: "component---src-pages-graph-2-d-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */)
}

